import React from 'react';
import { graphql } from 'gatsby';
import { Layout, SEO } from 'components';
import { Slices } from '../slices/slices';

const Location = (props) => {
  const { location, data } = props;
  const siteName = data && data.site && data.site.siteMetadata && data.site.siteMetadata.siteName;
  const dataSlices = data && data.prismicLocation && data.prismicLocation.data && data.prismicLocation.data.body;
  const dataPage = data && data.prismicLocation && data.prismicLocation.data;
  const firstSlice = dataSlices && dataSlices[0] && dataSlices[0].slice_type;
  const isHero = firstSlice === 'hero_image' || firstSlice === 'hero_video';
  return (
    <Layout location={location} isHero={isHero}>
      <SEO title={`${dataPage?.title?.text} | ${siteName}`} desc={dataPage?.description?.text} />
      <Slices data={dataSlices} />
    </Layout>
  );
};

export default Location;

export const Query = graphql`
  query($uid: String!) {
    site {
      siteMetadata {
        siteName
      }
    }
    prismicLocation(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        description {
          text
        }
        body {
          ...LocationAccordion
          ...LocationContentDisplay
          ...LocationFeatures
          ...LocationFeaturesImages
          ...LocationForm
          ...LocationHeroImage
          ...LocationHeroVideo
          ...LocationImageSlider
          ...LocationImageText
          ...LocationList
          ...LocationProcess
          ...LocationQuote
          ...LocationStatistics
          ...LocationText
          ...LocationVideo
        }
      }
    }
  }
`;
